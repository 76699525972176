.CommunicatorDialog__container {
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 300px;
  overflow: hidden;
  padding: 0;
}

.CommunicatorDialog__tabs {
  background-color: #3f51b5;
  color: #fff;
}

.is-dark .CommunicatorDialog__tabs {
  background-color: #78909c;
  color: #fff;
}

.CommunicatorDialog__tabs [class^='TabIndicator-'] {
  background-color: #fff;
  height: 3px;
}

.CommunicatorDialog__content {
  height: calc(100% - 48px);
  overflow: auto;
  padding: 0;
}

.is-dark .CommunicatorDialog__content {
  background-color: #5e5e5e;
}

.CommunicatorDialog__content .CommunicatorDialog__spinner {
  margin: 40px auto;
  display: block;
}

.CommunicatorDialog__communicatorData {
  background: #eee;
  padding: 16px 16px 28px;
}
.is-dark .CommunicatorDialog__communicatorData {
  background: rgba(36, 36, 36, 0.933);
}

.CommunicatorDialog__communicatorData__title {
  font-size: 1.14rem;
}

.CommunicatorDialog__communicatorData__boardsQty {
  font-size: 0.85rem;
  margin-top: 5px;
  color: #888;
}

.CommunicatorDialog__boards {
  background: #fff;
  padding: 20px 16px;
}

.is-dark .CommunicatorDialog__boards {
  background-color: #424242;
}

.CommunicatorDialog__boards__item {
  display: flex;
  min-height: 150px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.CommunicatorDialog__boards__item__data__title__secondary {
  display: flex;
  align-items: center;
  align-content: space-between;
}
.is-dark .CommunicatorDialog__boards__item {
  background-color: #424242;
}

.CommunicatorDialog__boards__item__data {
  position: relative;
  width: 50%;
  padding-left: 20px;
}

[dir='rtl'] .CommunicatorDialog__boards__item__data {
  padding-left: 0;
  padding-right: 20px;
}

.CommunicatorDialog__boards__item__image {
  position: relative;
  width: 40%;
}

.CommunicatorDialog__boards__item__image_container {
  position: relative;
  width: 100%;
}

.CommunicatorDialog__boards__item__image_container button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 5px;
  min-width: 20px;
  background-color: #838383;
}

.CommunicatorDialog__boards__item__image_container svg {
  color: #fff;
  font-size: 1.2rem;
}

.CommunicatorDialog__boards__item__actions {
  position: relative;
  width: 10%;
  color: #838383;
}

.CommunicatorDialog__boards__item__image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.CommunicatorDialog__boards__item__image__empty {
  height: 100%;
  background: #eee;
  position: relative;
}

.CommunicatorDialog__boards__item__image__empty button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 5px;
  min-width: 20px;
  background-color: #838383;
}

.CommunicatorDialog__boards__item__image__empty button svg {
  color: #fff;
  font-size: 1.2rem;
}

.CommunicatorDialog__boards__item__image__empty .ViewModuleIcon {
  position: absolute;
  font-size: 3.42rem;
  color: #aaa;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}
.CommunicatorDialog__boards__item__image__button {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.CommunicatorDialog__boards__item__image__button svg {
  font-size: 1.3rem;
  color: #777;
  opacity: 0.7;
}

.CommunicatorDialog__boards__item__image__button svg:hover {
  opacity: 1;
}

.CommunicatorDialog__boards__item__data__button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.CommunicatorDialog__boards__item__data__title,
.CommunicatorDialog__boards__item__data__author,
.CommunicatorDialog__boards__item__data__locale,
.CommunicatorDialog__boards__item__data__extra {
  margin-top: 2px;
  display: flex;
}

.CommunicatorDialog__boards__item__data__title svg {
  color: #777;
}

.CommunicatorDialog__boards__item__data__date {
  margin-top: 5px;
  font-size: smaller;
  color: grey;
}

.CommunicatorDialog__boards__item__data__extra {
  color: #369b00;
  margin-top: 10px;
}

.CommunicatorDialogButtons__container {
  margin: 0 20px 0 auto;
  display: flex;
}

.CommunicatorDialogButtons__container > div {
  margin-left: 20px;
}

.CommunicatorDialogButtons__searchInput input {
  min-width: 250px;
  background-color: rgb(99, 112, 212);
  color: #fff;
  border-radius: 4px;
}

.is-dark .CommunicatorDialogButtons__searchInput input {
  background-color: #424242;
}

.CommunicatorDialogButtons__searchInput [class*='MuiInput-underline-']:before,
.CommunicatorDialogButtons__searchInput [class*='MuiInput-underline-']:after {
  border-bottom-color: #fff;
}

.CommunicatorDialogButtons__searchInput
  [class*='MuiInput-underline-']:hover:not([class^='MuiInput-disabled']):not([class^='MuiInput-focused']):not([class^='MuiInput-error']):before,
.CommunicatorDialogButtons__searchInput
  [class*='MuiInput-underline-']:hover:not([class^='MuiInput-disabled']):not([class^='MuiInput-focused']):not([class^='MuiInput-error']):after {
  border-bottom-color: #fff;
}

#communicator-dialog-buttons-menu a {
  color: inherit;
  text-decoration: none;
}

.CommunicatorDialog__imageDialog__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CommunicatorDialog__imageDialog__content img {
  max-width: 300px;
}

.CommunicatorDialog__boardInfoDialog {
  user-select: text;
}

.CommunicatorDialog__Tour svg {
  color: #838383;
}

.CommunicatorDialog__Tour__boardProperty svg {
  color: #369b00;
}

.CommunicatorDialog__Tour li {
  padding: 0px;
}

.CommunicatorDialog__boards__item__edit-title {
  display: inline;
  margin: 0;
  padding: 0;
}

.CommunicatorDialog__board-report-dialog {
  width: minmax(auto, 50ch);
  min-width: auto;
  min-height: auto;
}

.CommunicatorDialog__board-report-dialog-title {
  display: grid;
  grid-template-columns: minmax(auto, 90%) 2em;
  align-items: center;
}

.CommunicatorDialog__board-report-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CommunicatorDialog__board-report-success
  > .CommunicatorDialog__board-report-success-icon {
  width: 3em;
  height: auto;
  margin-bottom: 30px;
  color: rgba(7, 148, 77, 0.918);
}
